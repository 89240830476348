<template>
  <div class="tp-rank">
    <div class="rank-top">
      <div class="player-top" @click="bindPlayerChange(ranks[1].id)">
        <div class="cover" v-if="config.hasPlayerCover">
          <img class="avatar" :src="ranks[1].imageUrl | ossAvatarFilter" alt="">
          <img class="top" src="../../../assets/images5/rank-top2.png" alt="">
        </div>
        <div class="info">
          <span class="num" v-if="config.hasPlayerNumber">
            {{ ranks[1].num }}号
          </span>
          <div class="vote" v-if="config.hasPlayerVotes">{{ ranks[1].voteNum }} {{ voteUnit }}</div>
          <div class="name" v-if="config.hasPlayerName">
            {{ ranks[1].name }}
          </div>
          <div class="descr" v-if="config.hasPlayerSynopsis">{{ ranks[1].intro }}</div>
        </div>
      </div>
      <div class="player-top" @click="bindPlayerChange(ranks[0].id)">
        <div class="cover" v-if="config.hasPlayerCover">
          <img class="avatar" :src="ranks[0].imageUrl | ossAvatarFilter" alt="">
          <img class="top" src="../../../assets/images5/rank-top1.png" alt="">
        </div>
        <div class="info">
          <span class="num" v-if="config.hasPlayerNumber">
            {{ ranks[0].num }}号
          </span>
          <div class="vote" v-if="config.hasPlayerVotes">{{ ranks[0].voteNum }} {{ voteUnit }}</div>
          <div class="name">
            {{ ranks[0].name }}
          </div>

          <div class="descr" v-if="config.hasPlayerSynopsis">{{ ranks[0].intro }}</div>
        </div>
      </div>
      <div class="player-top" @click="bindPlayerChange(ranks[2].id)">
        <div class="cover" v-if="config.hasPlayerCover">
          <img class="avatar" :src="ranks[2].imageUrl | ossAvatarFilter" alt="">
          <img class="top" src="../../../assets/images5/rank-top3.png" alt="">
        </div>
        <div class="info">
          <span class="num" v-if="config.hasPlayerNumber">
            {{ ranks[2].num }}号
          </span>
          <div class="vote" v-if="config.hasPlayerVotes">{{ ranks[2].voteNum }} {{ voteUnit }}</div>
          <div class="name">
            {{ ranks[2].name }}
          </div>
          <div class="descr" v-if="config.hasPlayerSynopsis">{{ ranks[2].intro }}</div>
        </div>
      </div>
    </div>
    <div class="group-bg rank-list">
      <van-list v-model="load" @load="loadNextPage" :finished="finish"
                finished-text="没有更多了">
        <div v-for='(item,index) in ranks' :key='index'>
          <div v-if="index>2" class="item" @click="bindPlayerChange(item.id)">
            <div class="rank">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="avatar" v-if="config.hasPlayerCover">
              <img :src="item.imageUrl | ossAvatarFilter" alt="">
            </div>
            <div class="info">
              <div class="content">
                <div class="name"><span class="num" v-if="config.hasPlayerNumber">{{ item.num }}号</span>{{ item.name }}
                </div>
                <div class="vote" v-if="config.hasPlayerVotes">{{ item.voteNum }} {{ voteUnit }}</div>
              </div>
              <div class="descr" v-if="config.hasPlayerSynopsis">{{ item.intro }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";

export default {
  name: 'tpPlayer',
  data() {
    return {
      config: {},
      voteUnit: ''
    }
  },
  props: ['load', 'ranks', 'finish'],
  created() {
    let {settingShowElement, settingShowRanking} = store.state.activity
    this.config = settingShowRanking
    this.voteUnit = settingShowElement.votesUnit
  },
  methods: {
    loadNextPage() {
      this.$emit('onLoad');
    },

    bindPlayerChange(playerId) {
      this.$emit('onDetail', playerId);
    },
  }
}
</script>
