<template>
  <div class="group-bg tp-rank">
    <div class="tp-title">
      <h2></h2>
    </div>
    <van-list v-model="load" @load="loadNextPage" :finished="finish"
              finished-text="没有更多了">
      <div class="item" v-for='(item,index) in ranks' :key='index' @click="bindPlayerChange(item.id)">
        <div class="rank">
          <span v-if="index>2">{{ index + 1 }}</span>
        </div>
        <div class="avatar" v-if="config.hasPlayerCover">
          <img  :src="item.imageUrl | ossAvatarFilter" alt="">
        </div>
        <div class="info">
          <div class="content">
            <div class="name">
              <span class="num" v-if="config.hasPlayerNumber">{{ item.num }}号</span>
              <span>{{ item.name }}</span>
            </div>
            <div class="vote" v-if="config.hasPlayerVotes">{{ item.voteNum }} {{ voteUnit }}</div>
          </div>
          <div class="descr" v-if="config.hasPlayerSynopsis">{{ item.intro }}</div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>

import store from "@/store/store";

export default {
  name: 'tpPlayer',
  data() {
    return {
      config: {},
      voteUnit: ''
    }
  },
  props: ['load', 'ranks', 'finish'],
  created() {
    store.state.isShowBanner = true;
    let {settingShowElement, settingShowRanking} = store.state.activity
    this.config = settingShowRanking
    this.voteUnit = settingShowElement.votesUnit
  },
  methods: {
    loadNextPage() {
      this.$emit('onLoad');
    },

    bindPlayerChange(playerId) {
      this.$emit('onDetail', playerId);
    },
  }
}
</script>
