<template>
  <div class="tp-rank">
    <div class="rank-list">
      <van-list v-model="load" @load="loadNextPage" :finished="finish"
                finished-text="没有更多了">
        <div class="item" v-for='(item,index) in ranks' :key='index' @click="bindPlayerChange(item.id)">
          <div class="rank-num-vote">
            <div class="rank">
              <span v-if="index>2">{{ index + 1 }}</span>
            </div>
            <div class="num-vote">
              <div class="num" v-if="config.hasPlayerNumber">
                {{ item.num }}号
              </div>
              <div class="vote" v-if="config.hasPlayerVotes">
                {{ item.voteNum }} {{ voteUnit }}
              </div>
            </div>
          </div>
          <div class="avatar-name-descr">
            <div class="avatar" v-if="config.hasPlayerCover">
              <img :src="item.imageUrl | ossAvatarFilter" alt="">
            </div>
            <div class="name-descr">
              <div class="name" v-if="config.hasPlayerName">{{ item.name }}</div>
              <div class="descr" v-if="config.hasPlayerSynopsis">{{ item.intro }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";

export default {
  name: 'tpPlayer',
  data() {
    return {
      config: {},
      voteUnit: ''
    }
  },
  props: ['load', 'ranks', 'finish'],
  created() {
    let {settingShowElement, settingShowRanking} = store.state.activity
    this.config = settingShowRanking
    this.voteUnit = settingShowElement.votesUnit
  },
  methods: {
    loadNextPage() {
      this.$emit('onLoad');
    },

    bindPlayerChange(playerId) {
      this.$emit('onDetail', playerId);
    },
  }
}
</script>
